import React, { useState, useEffect, useRef } from 'react';
import Head from 'next/head';

// import Loader from '../ui/loader';
import css from './demo.module.css';

const DemoView: React.FC = () => {
    const refVideo = useRef<HTMLVideoElement>();
    // const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
    // const onLoadedData = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    //     setIsVideoLoaded(true);
    // };

    return (
        <>
            <Head>
                <title>Rumble Kong League</title>
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/images/favicon/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/images/favicon/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/images/favicon/favicon-16x16.png"
                />
                <link rel="manifest" href="/images/favicon/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/images/favicon/safari-pinned-tab.svg"
                    color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#000" />
                <meta name="theme-color" content="#000" />
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
            </Head> 
            <div id="page" className={css.page}>
                {/* {!isVideoLoaded && (
                    <div className={css.loader}>
                        <Loader/>
                    </div>
                )} */}
                <video
                    ref={refVideo}
                    className={css.video}
                    controls
                    // onLoadedData={onLoadedData}
                    src="/video/rkl-demo.mp4?xxxx"
                />
            </div>
        </>
    );
};

export default DemoView;
